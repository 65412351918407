<template>
  <div>
    <a-card title="服务详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions title="服务基础信息" bordered>
        <a-descriptions-item label="服务ID">{{ form.id }} </a-descriptions-item>
        <a-descriptions-item label="服务标识符"> {{ form.identifier }} </a-descriptions-item>
        <a-descriptions-item label="超时时间"> {{ `${form.timeout}ms` }} </a-descriptions-item>
        <a-descriptions-item label="服务描述" v-if="form.description">{{
          form.description.defaultMessage ? form.description.defaultMessage : '暂无'
        }}</a-descriptions-item>
      </a-descriptions>
      <template v-if="form.parameters && form.parameters.length > 0">
        <div class="loadDevice_title">服务参数项</div>
        <a-descriptions
          class="params_descriptions"
          v-for="parameter in form.parameters"
          :key="parameter.identifier"
          :column="2"
          bordered
          style="margin-top: 1%"
        >
          <a-descriptions-item label="参数项标识符"> {{ parameter.identifier }} </a-descriptions-item>
          <a-descriptions-item label="参数项名称"> {{ parameter.description.defaultMessage }} </a-descriptions-item>
          <a-descriptions-item label="属性类型">
            <span>{{ propertyType(parameter) }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="编辑方式">
            <span>{{ propertyEditMode(parameter) }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="默认值">
            <span>{{ parameter.type.defaultValue || '无' }}</span>
          </a-descriptions-item>
          <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.input">
            <a-descriptions-item label="范围">
              <span v-if="parameter.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                `${parameter.type.range.min}-${parameter.type.range.max}`
              }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
          </template>
          <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.select">
            <a-descriptions-item label="选项">
              <p v-for="(option, index) in parameter.type.options" :key="index">
                {{ `${option.value}:${option.description.defaultMessage}` }}
              </p>
            </a-descriptions-item>
          </template>
        </a-descriptions>
      </template>
    </a-card>
    <a-card title="服务数据" style="width: 80%; margin: 0 auto; margin-bottom: 2%">
      <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 800px" />
      <a-button type="primary" class="creator-btn" @click="$router.back()">返回</a-button>
    </a-card>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';

export default {
  name: 'PropertyDetail',
  components: { vueJsonEditor },
  mounted() {
    if (this.$route.params.id) {
      Promise.all([this.getMetaData(), this.getProductService(this.$route.params.id)]);
    }
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      metaData: {},
    };
  },
  methods: {
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    async handleLanguage() {
      await this.getProductService(this.$route.params.id);
    },
    async getProductService(ids) {
      const headers = {
        'Accept-Language': this.languageSelect,
      };
      const response = await this.$apiManager.productService.getproductServiceById(ids, headers);
      this.form = response.data[0];
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const typeName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return typeName ? typeName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => this.$constRepository.productFormItem.editModeText[property.type.editMode] || '';
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.creator-btn {
  margin-top: 2%;
}
.default-message-edit {
  height: 8vw;
  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}
.loadDevice_title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.params_descriptions {
  /deep/ .ant-descriptions-item-content {
    width: 300px;
    word-break: break-all;
  }
}
</style>
